<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.aranr_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div class="content">
            <validation-observer ref="VForm">
                <b-form @submit.prevent="doSubmit">
                <div class="card">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">RADIOLOGI</h6>
                </div>
                
                <div class="card-body p-3">
                    <table class="table table-bordered mb-3">
                    <thead>
                        <tr class="table-light">
                        <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>
                            <div class="result_tab">
                            <h4>Prioritas Pemeriksaan</h4>
                            <p v-if="row.arantr_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                            <p v-else>Non CITO</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                            <h4>Pasien Memiliki Alergi Bahan Kontras/Zat?</h4>
                            <p>{{row.arantr_alergi=='Y'?'Ya':'Tidak'}}</p>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <div class="req-list-resume mb-3">
                    <div class="row g-3">
                        <template v-for="(v,k) in row.arantr_data">
                        <div class="col-md-4 col-lg-3" :key="k+'radioData'" v-if="isShowHeadRadiologi(k)">
                            <div class="card shadow-0 border">
                            <div class="card-header py-2 bg-light">
                                <h6 class="card-title text-uppercase font-weight-semibold">{{
                                getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                            </div>
                            <div class="card-body py-2">
                                <template v-for="v1,k1 in (v.data||[])">
                                <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                                    <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                    <span class="align-middle font-weight-semibold">{{v1.text||"-"}}</span>
                                    <span v-if="v1.notes">, {{v1.notes}}</span>
                                    
                                    <a href="javascript:;" @click="openInformConsentDetailRadiologi(v1,k1,k,getConfigDynamic(Config.mr.KategoriRadiologi,v.head))" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                    <i class="icon-file-eye2"></i>
                                    </a>
                                </div>
                                </template>
                            </div>
                            </div>
                        </div>
                        </template>
                        <div class="col-md-4 col-lg-3" v-if="row.arantr_data_lainnya">
                        <div class="card shadow-0 border">
                            <div class="card-header py-2 bg-light">
                            <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                            </div>
                            <div class="card-body py-2">
                                <div class="req-list">
                                    <div>
                                    <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                    <span class="align-middle">{{row.arantr_data_lainnya||"-"}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <span v-if="!selectedRadioInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                    </div>
                    <div class="text-center mt-3" v-if="canEditRadiologi">
                        <a href="javascript:;" @click="doOpenRadiologi" class="btn alpha-blue"
                        data-toggle="modal" data-target="#RadiologiItemsChecklist"><i
                            class="icon-plus2 mr-2"></i>Tambah Pemeriksaan Radiologi</a>
                    </div>
                    </div>
                    <div class="card border mb-0">
                    <div @click="showPatient = !showPatient" class="card-header bg-info" style="cursor:pointer;" data-toggle="collapse" data-target="#infoPatient">
                        <div class="d-flex align-items-center justify-content-between">
                        <h6 class="card-title font-weight-semibold">Lihat Informasi Pasien</h6>
                        <i class="icon-chevron-down"></i>
                        </div>
                    </div>
                    <div v-if="showPatient" id="infoPatient">
                        <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered table-sm">
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>No. Rekam Medis</h4>
                                    <p>{{row.ap_code||"-"}} </p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Nama Pasien</h4>
                                    <p>{{row.ap_fullname||"-"}} </p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Tgl. Lahir</h4>
                                    <p>{{row.ap_dob | moment("DD MMM YYYY")}}</p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Tinggi Badan</h4>
                                    <p v-if="dataDokter.arankm_ttv_height">{{dataDokter.arankm_ttv_height||"- "}}cm</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Berat Badan</h4>
                                    <p v-if="dataDokter.arankm_ttv_weight">{{dataDokter.arankm_ttv_weight||"- "}}cm</p>
                                    <p v-else> - </p>

                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Luas Permukaan Badan</h4>
                                    <template v-if="dataDokter.arankm_ttv_bmi || dataDokter.arankm_ttv_luas_permukaan_anak">
                                        <p v-if="row.ap_usia > 15">{{dataDokter.arankm_ttv_bmi||"- "}}m<sup>2</sup></p>
                                        <p v-else>{{dataDokter.arankm_ttv_luas_permukaan_anak||"- "}}m<sup>2</sup></p>
                                    </template>
                                    <p v-else> - </p>
                                </div>
                                </td>
                            </tr>
                            </table>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                </div>
                <div class="card">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">UPLOAD HASIL RADIOLOGI</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                    
                    <div class="col-md-12 mb-2">
                        <b-form-checkbox value="Y" unchecked-value="N" :name="'hasilLab'" v-model="row.arantr_is_menunggu_hasil">Hasil Pemeriksaan Belum Keluar
                        </b-form-checkbox>
                    </div>

                    <div class="col-md-12">
                        <table class="table table-bordered table-striped table-sm patient-table">
                        <thead>
                            <tr>
                            <th>Hasil Pemeriksaan </th>
                            <th>Upload Hasil</th>
                            <th>Jam Keluar Hasil</th>
                            <th>Nama Dokter</th>
                            <th>Nilai Kritis</th>
                            <th>Jam Keluar Hasil Kritis</th>
                            <th>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(v,k) in (row.arantr_hasil||[])" :key="k+'hasil'">
                            <td>
                                <b-form-textarea v-model="row.arantr_hasil[k]['value']" type="text" name="name" class="form-control" />
                                <VValidate :name="'Hasil Pemeriksaan #'+(k+1)" v-model="row.arantr_hasil[k]['value']"
                                :rules="{required:1, min:2}" />
                            </td>
                            <td>
                                <Uploader v-model="row.arantr_hasil[k]['file']" isDocument type="docimage" />
                                <VValidate :name="'File Pemeriksaan #'+(k+1)" v-model="row.arantr_hasil[k]['file']"
                                :rules="{required:1}" />
                            </td>
                            <td>
                                <div>
                                    <div class="input-group mb-2">
                                        <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                        </div>
                                        <datepicker :clear-button="true" input-class="form-control transparent" 
                                            calendar-class="my-datepicker"
                                            v-model="row.arantr_hasil[k]['tanggal_keluar_hasil']">
                                        </datepicker>
                                    </div>

                                    <div class="input-group bootstrap-timepicker timepicker mb-2">
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arantr_hasil[k]['jam_keluar_hasil']">
                                        </vue-timepicker>
                                    </div>
                                </div>


                                <VValidate :name="'Tanggal Keluar Pemeriksaan Pemeriksaan #'+(k+1)" v-model="row.arantr_hasil[k]['tanggal_keluar_hasil']"
                                :rules="{required:1}" />
                                

                                <VValidate :name="'Jam Keluar Pemeriksaan Pemeriksaan #'+(k+1)" v-model="row.arantr_hasil[k]['jam_keluar_hasil']"
                                :rules="{required:1}" />
                            </td>
                            <td>
                                <b-form-input v-model="row.arantr_hasil[k]['dokter']" type="text" name="name" class="form-control" />
                            </td>
                            <td>
                                <b-form-textarea v-model="row.arantr_hasil[k]['nilai_kritis']" type="text" name="name" class="form-control" />
                            </td>
                            <td>
                                <div class="input-group">
                                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arantr_hasil[k]['jam_keluar']">
                                    </vue-timepicker>
                                    
                                    <VValidate v-if="row.arantr_hasil[k]['nilai_kritis']" :name="'Jam Keluar Hasil #'+(k+1)" v-model="row.arantr_hasil[k]['jam_keluar']"
                                    :rules="{required:1}" />
                                </div>
                            </td>
                            <td> 
                            <a href="javascript:;" class="list-icons-item"
                                @click="row.arantr_hasil.splice(k,1)"
                                data-toggle="tooltip" data-placement="top" title="Delete"><i
                                class="icon-bin"></i></a>
                            </td>
                            </tr>
                            <tr v-if="!(row.arantr_hasil||[]).length">
                                <td colspan="99" class="text-center">Tidak Ada Data</td>
                            </tr>
                        </tbody>
                        </table>

                        <div class="more_data">
                        <a href="javascript:;" @click="addNew"><i class="icon-plus-circle2"></i>
                            Tambah</a>
                        </div>
                    </div>

                    </div>
                </div>
                <div class="card border shadow-0 mt-3" v-if="row.arantr_is_have_hasil == 'Y'">
                    <div class="card-header bg-info">
                        <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                        <label for="addInfoTindakan">Keterangan Perubahan</label>
                        <b-textarea v-model="row.arantl_notes" :formatter="$parent.normalText" rows="6" class="form-control" placeholder="Keterangan Perubahan">
                        </b-textarea>

                            
                        <VValidate 
                            :name="`Keterangan Perubahan`" 
                            v-model="row.arantl_notes" 
                            :rules="{required: 1, min: 2, max:512}"
                        />
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="text-right">
                        <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                        <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                    </div>
                </div>
                </div>
                </b-form>
            </validation-observer>

            <b-modal v-model="openRadiologi" :title="'Pilih Item Pemeriksaan Radiologi'" size="xl" hide-footer>
                <div class="modal-header d-block p-0 mt-3">
                <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
                    <li v-for="(v,k) in row.arantr_data" :key="k" @click="changeTabRadiologi(k)" class="nav-item">
                    <a href="javascript:;" data-toggle="tab"
                        :class="k == activeTabRadiologi ? 'nav-link active' : 'nav-link'">{{getConfigDynamic(Config.mr.KategoriRadiologi, v.head)||"-"}}</a>
                    </li>
                </ul>
                </div>

                <div class="modal-body p-0 mt-3">
                <div class="tab-content">
                    <div v-for="(v,k) in row.arantr_data" :key="k+'radform'"
                    :class="k == activeTabRadiologi ? 'tab-pane fade show active' : 'tab-pane fade'">
                    <div class="row sc-row g-0">
                        <div class="col-md-3" v-for="(v1,k1) in (v.data||[])" :key="k1+'radio'">
                        <div class="selection-control">
                            <b-form-checkbox :name="'tabrad'+String(v1.id)" v-model="v1['selected']">{{v1.text||"-"}}
                            </b-form-checkbox>
                            <b-form-input type="text" v-if="v1['selected']" v-model="v1['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row ml-1 mt-2">
                    <div class="col-md-4">
                    <div class="form-group row">
                        <label>Lainnya</label>
                        <b-form-input v-model="row.arantr_data_lainnya" type="text" class="form-control" />
                    </div>
                    </div>
                </div>
                </div>

                <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
                <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
                <span class="m-0" v-if="selectedRadioInput()">{{selectedRadioInput()}}</span>
                <span v-else> - </span>
                </div>
                <div class="modal-footer p-0 mt-3 bg-white">
                <div class="text-right">
                    <button @click="openRadiologi = false" data-dismiss="modal" class="btn">Selesai</button>
                </div>
                </div>
            </b-modal>
            
            <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
                <table class="table table-bordered table-sm table-input">
                <thead>
                    <tr>
                    <td colspan="3">
                        <div class="result_tab">
                            <h4>Nama Tindakan</h4>
                            <p>{{rowIC.tindakan}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <th width="24">No.</th>
                    <th>Jenis Informasi</th>
                    <th>Isi Informasi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
                    <td>
                    {{rowIC.aranic_diagnosis||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>2</td>
                    <td>Dasar Diagnosis</td>
                    <td>
                    {{rowIC.aranic_dasar_diagnosis||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>Tindakan Kedokteran</td>
                    <td>
                    {{rowIC.aranic_tindakan_dokter||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>4</td>
                    <td>Indikasi Tindakan</td>
                    <td>
                    {{rowIC.aranic_indikasi_tindakan||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>5</td>
                    <td>Tata Cara</td>
                    <td>
                    {{rowIC.aranic_tata_cara||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>6</td>
                    <td>Tujuan</td>
                    <td>
                    {{rowIC.aranic_tujuan||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>7</td>
                    <td>Risiko</td>
                    <td>
                    {{rowIC.aranic_risiko||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>8</td>
                    <td>Komplikasi</td>
                    <td>
                    {{rowIC.aranic_kompilasi||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>9</td>
                    <td>Prognosis</td>
                    <td>
                    {{rowIC.aranic_prognosis||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>10</td>
                    <td>Alternatif & Risiko</td>
                    <td>
                    {{rowIC.aranic_alternatif||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>11</td>
                    <td>Lain-lain</td>
                    <td>
                    {{rowIC.aranic_lainnya||"-"}}
                    </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                    <td width="50%" colspan="2">
                        <div class="result_tab">
                        <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                        <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                        <p v-else>Tindakan ditolak</p>
                        </div>
                    </td>
                    <td width="50%">
                        <div class="result_tab">
                        <h4>Persetujuan tindakan diberikan kepada</h4>
                        <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                        <p v-else>Penanggung Jawab</p>
                        </div>
                    </td>
                    </tr>
                </tfoot>
                </table>
            </b-modal>
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
const moment = require('moment')
import Gen from '@/libs/Gen.js'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Datepicker from 'vuejs-datepicker'


export default {
    extends: GlobalVue,
    data(){
        return {
            rowReg: {},
            dataDokter: {},
            showPatient: false,
            openRadiologi: false,
            activeTabRadiologi: 0,
            openModalICDetail: false,
            rowIC: {}
        }
    },
    components:{ 
        VueTimepicker,Datepicker
    },
    computed: {  
      canEditRadiologi() {
        return true   
      },
    },
    methods: {
        openInformConsentDetailRadiologi(v,k1,k,kat){
            Gen.apiRest('/do/' + 'RanapPasienTindakanRadiologi', {
                data: {
                    type: 'get-data-ic-single',
                    id: v.inform_consent,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.tindakan = v.text  + "("+kat+")"
                this.rowIC.id_tindakan = v.inform_consent
                 
                this.rowIC.k1 = k1
                this.rowIC.k = k

                this.openModalICDetail = true                   
            })
        }, 
        back(){
            this.$router.back()
        },
        apiGet(params = {}, page = 1){
            if(!this.pageSlug){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }

            if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.pageNow = page
                this.last_page = this.data.last_page
                
                if(!this.isFound){
                    this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
            }).catch(()=>{
                this.loadingOverlay = false
            })
            
        },
        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },

        doSubmit(){
            this.$refs['VForm'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                         setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                
                if(!(this.row.arantr_hasil||[]).length && this.row.arantr_is_menunggu_hasil != 'Y'){
                    return this.$swal({
                        icon: 'error',
                        title: 'Tidak ada Hasil Radiologi, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }

                 if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'
                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+this.modulePage,
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                      this.$router.push({ name: this.modulePage })
                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })

                

        },

        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            if(data.ap_usia <= 6){
                data.aukpn_skala_nyeri_value = this.hitungSkala
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:data}, 
                "POST"
            )
        },1000),


        // copas dari ssini

        changeTabRadiologi(e) {
          this.activeTabRadiologi = e
        },
        doOpenRadiologi() {
          this.openRadiologi = true
        },
        isShowHeadRadiologi(j){
          let isData = 0
          for(let k = 0; k < (this.row.arantr_data[j]['data']||[]).length; k++){  
            if(this.row.arantr_data[j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowRadiologi(j,k){
          let isData = 0
          if(this.row.arantr_data[j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },
        selectedRadioInput(){
          let data = []
          for(let i = 0; i < (this.row.arantr_data||[]).length; i++){
            for(let j = 0; j < (this.row.arantr_data[i]['data']||[]).length; j++){  
                if(this.row.arantr_data[i]['data'][j]['selected']){
                  data.push(this.row.arantr_data[i]['data'][j]['text'])
                }
            }
          }
          if(this.row.arantr_data_lainnya){
            data.push(this.row.arantr_data_lainnya)
          }
          return data.join(", ")
        },
        addNew(){
            this.row.arantr_hasil.push({
                value: null,
                file: null,
                tanggal_keluar_hasil: moment().format('YYYY-MM-DD'),
                jam_keluar_hasil: moment().format('HH:mm'),
                dokter:null,
                nilai_kritis: null,
                jam_keluar: '',
                dilaporkan_oleh: null,
                dilaporkan_pada: null,
            })
        },
        toValidate(val){
            return {...val}
        },

    },
    mounted() {
        setTimeout(()=>{
            this.initSticky()
        },1000)
        document.body.classList.add('sidebar-xs')
        setTimeout(()=>{
            this.row.arantr_notes = null
        },1500)
        this.apiGet()
    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
    }
}
</script>
