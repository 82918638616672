<template>
  <div>
    <validation-observer ref="VFormInformConsent">
      <b-form @submit.prevent="doSubmit">
        <div class="row">
          <div class="col-md-12">
            <ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
              <li class="nav-item"><a href="javascript:;" @click="changeTab('IC')" 
              :class="activeTab == 'IC' ? 'nav-link active' : 'nav-link'">Inform Consent</a>
              </li>
              <li class="nav-item"><a href="javascript:;" @click="changeTab('IntraPenunjang')" 
              :class="activeTab == 'IntraPenunjang' ? 'nav-link active' : 'nav-link'"> Transfer Intra Penunjang</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <div class="tab-content">
              <div :class="activeTab == 'IC' ?'tab-pane fade show active':'tab-pane fade'" id="inform-consent-lab">
                <div class="row">
                    <table class="table table-bordered table-striped table-hover table-sm patient-table">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Nama Tindakan</th>
                          <th>Tindakan Disetujui Oleh Pasien</th>
                          <th>Dokumen</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (informConcent||[])" :key="k+'informConsent'">
                          <td>{{k+1}}</td>
                          <td>{{v.aranic_name||"-"}}</td>
                          <td>
                            <span>{{v.aranic_is_pasien_setuju == 'Y'?'Setuju':'Tidak Setuju'}}</span>
                          </td>
                          <td>
                            <a class="btn_view" v-if="v.aranic_file" :href="isParent.uploader(v.aranic_file)" target="_blank"><i class="icon-file-download"></i></a>
                            <span v-else> - </span>
                          </td>
                        </tr>

                        <!---->
                      </tbody>
                    </table>
                </div>
              </div>
              <div :class="activeTab == 'IntraPenunjang' ?'tab-pane fade show active':'tab-pane fade'"  id="form-permintaan-transifusi">
                <div class="row">
                  <div class="col-md-4">
                    

                    <div class="form-group">
                      <label for="">Nama & Tanda Tangan Pengirim</label>
                      <b-form-input type="text" v-model="isParent.rowEdit.arantr_pengirim_nama" class="form-control" placeholder="Nama Pengirim" />
                      <div class="signing-element mt-1" data-target-id="ttdPengirim">
                        <VueSignaturePad
                            class="sign-container"
                            width="100%"
                            height="160px"
                            ref="ttdPengirim"
                        />
                        <div class="mt-2">
                        <button type="button" @click="undoTTD('ttdPengirim','arantr_pengirim_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                            <b><i class="icon-cross3"></i></b>
                            <span>Clear</span>
                        </button>
                        
                        <button type="button" @click="output('ttdPengirim','arantr_pengirim_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                            <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                        </button>
                        </div>
                        
                        <VValidate name="Nama Pengirim" v-model="isParent.rowEdit.arantr_pengirim_nama"
                        :rules="{required:1}" />

                        <VValidate name="Tanda Tangan Pengirim" v-model="isParent.rowEdit.arantr_pengirim_ttd"
                        :rules="{required:1}" />
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="">Nama & Tanda Tangan Penerima</label>
                      <b-form-input type="text" v-model="isParent.rowEdit.arantr_penerima_nama" class="form-control" placeholder="Nama Penerima" />
                      <div class="signing-element mt-1" data-target-id="ttdPenerima">
                        <VueSignaturePad
                            class="sign-container"
                            width="100%"
                            height="160px"
                            ref="ttdPenerima"
                        />
                        <div class="mt-2">
                        <button type="button" @click="undoTTD('ttdPenerima','arantr_penerima_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                            <b><i class="icon-cross3"></i></b>
                            <span>Clear</span>
                        </button>
                        
                        <button type="button" @click="output('ttdPenerima','arantr_penerima_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                            <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                        </button>
                        </div>
                        
                        
                        <VValidate name="Nama Penerima" v-model="isParent.rowEdit.arantr_penerima_nama"
                        :rules="{required:1}" />

                        <VValidate name="Tanda Tangan Penerima" v-model="isParent.rowEdit.arantr_penerima_ttd"
                        :rules="{required:1}" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="document-framer">
                      <div class="df-content" v-html="isParent.rowEdit.arantr_tranfer_intra_temp">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <div class="text-right">
            <button type="button" class="btn" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
            </button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>

    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="xl" ok-only>
      <table class="table table-bordered table-sm table-input">
      <thead>
          <tr>
          <td colspan="3">
              <div class="result_tab">
                  <h4>Nama Tindakan</h4>
                  <p>{{rowIC.aranic_name}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <th width="24">No.</th>
          <th>Jenis Informasi</th>
          <th>Isi Informasi</th>
          </tr>
      </thead>
      <tbody>
          <tr>
          <td>1</td>
          <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
          <td>
          {{rowIC.aranic_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>2</td>
          <td>Dasar Diagnosis</td>
          <td>
          {{rowIC.aranic_dasar_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>3</td>
          <td>Tindakan Kedokteran</td>
          <td>
          {{rowIC.aranic_tindakan_dokter||"-"}}
          </td>
          </tr>
          <tr>
          <td>4</td>
          <td>Indikasi Tindakan</td>
          <td>
          {{rowIC.aranic_indikasi_tindakan||"-"}}
          </td>
          </tr>
          <tr>
          <td>5</td>
          <td>Tata Cara</td>
          <td>
          {{rowIC.aranic_tata_cara||"-"}}
          </td>
          </tr>
          <tr>
          <td>6</td>
          <td>Tujuan</td>
          <td>
          {{rowIC.aranic_tujuan||"-"}}
          </td>
          </tr>
          <tr>
          <td>7</td>
          <td>Risiko</td>
          <td>
          {{rowIC.aranic_risiko||"-"}}
          </td>
          </tr>
          <tr>
          <td>8</td>
          <td>Komplikasi</td>
          <td>
          {{rowIC.aranic_kompilasi||"-"}}
          </td>
          </tr>
          <tr>
          <td>9</td>
          <td>Prognosis</td>
          <td>
          {{rowIC.aranic_prognosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>10</td>
          <td>Alternatif & Risiko</td>
          <td>
          {{rowIC.aranic_alternatif||"-"}}
          </td>
          </tr>
          <tr>
          <td>11</td>
          <td>Lain-lain</td>
          <td>
          {{rowIC.aranic_lainnya||"-"}}
          </td>
          </tr>
      </tbody>
      <tfoot>
          <tr>
          <td width="50%" colspan="2">
              <div class="result_tab">
              <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
              <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
              <p v-else>Tindakan ditolak</p>
              </div>
          </td>
          <td width="50%">
              <div class="result_tab">
              <h4>Persetujuan tindakan diberikan kepada</h4>
              <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
              <p v-else>Penanggung Jawab</p>
              </div>
          </td>
          </tr>
      </tfoot>
      </table>
    </b-modal>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  data(){
    return {
      activeTab: 'IC',
      openInformConsent: false,
      informConcent: [],
      rowIC: {},
      openModalICDetail: false,
    }
  },
  methods: {
    
    
    selectedInformConsent(dataRaw){
      let data = []
      for(let i = 0; i < (dataRaw.arantr_data||[]).length; i++){
        for(let j = 0; j < (dataRaw.arantr_data[i]['data']||[]).length; j++){  
            if(dataRaw.arantr_data[i]['data'][j]['selected'] && dataRaw.arantr_data[i]['data'][j]['inform_consent']){
              data.push(dataRaw.arantr_data[i]['data'][j]['inform_consent'])
            }
        }
      }
      return data.join(", ")
    },

    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.isParent.rowEdit[field] = null
      this.autoSave(this.isParent.rowEdit)
    },
    output(refs,field) {
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.isParent.rowEdit[field] = data
        this.autoSave(this.isParent.rowEdit)
      }
    },

    autoSave: _.debounce(function (data) {
      this.getData()
    },1000),

    getData(){
      this.isParent.loadingOverlay = true
      let data = this.isParent.rowEdit
      data.id = this.isParent.rowEdit.arantr_id
      data.aranr_id = this.isParent.rowEdit.arantr_aranr_id
      data.type = 'get-inform-consent'
      data.selectedIC = this.selectedInformConsent(this.isParent.rowEdit)

      Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data: data}, 
          "POST"
      ).then(res=>{
        let resp = res.data
        this.isParent.loadingOverlay = false
        this.informConcent = resp.informConcent
        this.isParent.rowEdit = resp.rowEdit

        $(".sign-container canvas").attr("width",400)
        $(".sign-container canvas").attr("height",160)                
        this.$nextTick(() => {
          if(this.$refs['ttdPengirim']){
            this.$refs['ttdPengirim'].fromDataURL(this.isParent.rowEdit.arantr_pengirim_ttd)
          } 
          if(this.$refs['ttdPenerima']){
            this.$refs['ttdPenerima'].fromDataURL(this.isParent.rowEdit.arantr_penerima_ttd)
          } 
        })
      })
    },
    changeTab(tab){
      this.activeTab = tab
    },
    doSubmit(){
      this.$refs['VFormInformConsent'].validate().then(success => {
        if(success){
          let data = this.isParent.rowEdit
          data.type = 'submit-inform-consent'
          this.isParent.loadingOverlay = true
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
            this.isParent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              this.isParent.openModal = false
              this.isParent.apiGet()
            })
          }).catch(err => {
              this.isParent.loadingOverlay = false
              if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
              }
              this.isParent.doSetAlertForm(err)
          })
        }
      })
    },
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted() {
    this.getData()
  }
}

</script>